import React from "react";
import SingleServicePage from "../../components/SingleServicePage";
import withLayout from "../../layout";
import {injectIntl} from "react-intl";
import SEO from "../../components/SEO";
import mantegna from '../../images/portfolio/Mantegna.mp4';
import MantegnaThumb from '../../images/portfolio/MANTEGNA_thumbnail.webp';
import drop from '../../images/service_detail_drops/blue.svg';
import {BulletBlueIcon} from "../../images/icons";

/**
 * note that the key we defined in role is needed for building the SingleServicePage
 * component with the right translation strings and media
 * @returns { SingleServicePage }
 * @constructor
 */

const SingleServicePageWrapper = ({intl}) => {
    const IntlTextObj = {
        title: 'marketingStrategy.title',
        colorName: 'blueBase',
        color: '#499FF9',
        drop: drop,
        bullet: <BulletBlueIcon/>,
        fromService: 'marketing',
        paragraphs: [
            {title: 'marketingStrategy.inbound_marketing_title', text: 'marketingStrategy.inbound_marketing_desc'},
            {title: 'marketingStrategy.performance_advertising_title', text: 'marketingStrategy.performance_advertising_desc'}
        ],
        services_detail: ['marketingStrategy.detail_1','marketingStrategy.detail_2','marketingStrategy.detail_3','marketingStrategy.detail_4','marketingStrategy.detail_5',
            'marketingStrategy.detail_6','marketingStrategy.detail_7','marketingStrategy.detail_8','marketingStrategy.detail_9','marketingStrategy.detail_10',
            'marketingStrategy.detail_11','marketingStrategy.detail_12'],
        clients: [
            {
                video: mantegna,
                title: 'portfolio.mantegna.title',
                poster: MantegnaThumb,
                subtitle: 'portfolio.mantegna.description',
                to: '/case-study/mantegna',
            }
        ]
    };

    const keywords = intl.formatMessage({id: 'marketingStrategy.meta_keywords'}).split(',') || [];

    return <>
        <SEO title={intl.formatMessage({id: 'marketingStrategy.meta_title'})}
             description={intl.formatMessage({id: 'marketingStrategy.meta_description'})} keywords={keywords}
             lang={intl.locale}/>
        <SingleServicePage IntlTextObj={IntlTextObj} intl={intl}/>
    </>
};

const customProps = {
    localeKey: "marketingStrategy", // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(injectIntl(SingleServicePageWrapper));
